<template>
  <div>

    <div data-animation="default" class="navbar-component w-nav"
         data-easing2="ease"
         data-easing="ease"
         data-collapse="medium"
         data-w-id="11c4cfc9-926a-e542-1b82-38094d6cd2e0" role="banner"
         data-no-scroll="1" data-duration="250" data-doc-height="1"
         style="z-index: 999; padding-bottom: 10px;">
      <div class="navbar-container">
        <a @click="goHome" aria-current="page" class="navbar-logo-link w-nav-brand w--current"><img src="../../../../../public/images/brokton.svg" loading="lazy" alt="" class="navbar-logo"></a>
        <div id="w-node-df11f607-f470-9d9d-9899-a77ebcac00cb-4d6cd2e0"
             class="text-middle-nav flex space-between max-1100" style="gap: 10px;">
          <h5 @click="goToGetLoan" class="pointer">Как получить</h5>
          <h5 @click="goToHowToReturn" class="pointer">Как вернуть</h5>
          <h5 @click="aboutCompany" class="pointer">О компании</h5>
          <h5 @click="goToSupport" class="pointer">Помощь</h5>
        </div>
        <nav role="navigation" class="navbar-menu w-nav-menu">
          <div>
            <a @click="goToLogin" class="navbar-link w-nav-link pointer">Личный кабинет</a>
            <a @click="goToGetLoan" class="navbar-link w-nav-link pointer">Как получить</a>
            <a @click="goToHowToReturn" class="navbar-link w-nav-link pointer">Как вернуть</a>
            <a @click="aboutCompany" class="navbar-link w-nav-link pointer">О компании</a>
            <a @click="goToSupport" class="navbar-link w-nav-link pointer">Помощь</a>
          </div>
          <div class="w-layout-vflex paliteco">
            <a @click="goToDoc('privacy-policy')" class="link-heder">Политика конфиденциальности</a>
          </div>
        </nav>
        <div id="w-node-_11c4cfc9-926a-e542-1b82-38094d6cd2eb-4d6cd2e0" class="navbar-button-wrapper">
          <div class="navbar-menu-button w-nav-button w-nav-button-always">
            <div class="w-layout-hflex mine-menu w-button">
              <div class="button-text-menu">Меню</div>
              <div class="menu-icon2">
                <div class="menu-icon2-line-top"></div>
                <div class="menu-icon2-line-middle">
                  <div class="menu-icon-line-middle-inner"></div>
                </div>
                <div class="menu-icon2-line-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HeaderPersonal',
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  },
  methods: {
    goToSupport() {
      this.$router.push('/support');
    },
    aboutCompany() {
      this.$router.push('/about-company');
    },
    goHome() {
      this.$router.push('/');
    },
    goToDoc(documentType) {
      this.$router.push('/documents/' + documentType);
    },
    goToLogin() {
      if (this.$store.getters.AUTH_TOKEN) {
        this.$router.push('/personal-data');
      } else {
        this.$router.push('/login');
      }
    },
    goToGetLoan() {
      this.$router.push('/how-to-get-loan');
    },
    goToHowToReturn() {
      this.$router.push('/how-to-return');
    }
  }
};
</script>

<style>
</style>
